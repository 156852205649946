import { Layout } from '@components/layout';
import { AnimatedButton, AnimatedLink, Loader } from '@components/shared';
import React, { useState, useEffect, useRef } from 'react';

import Logo from './images/logo.svg';
import {
    container,
    container__content,
    container__content__image,
    container__content__contact,
    container__content__contact__button,
    container__content__contact__form,
    newsletter_email_field,
    newsletter_button,
    newsletter_header,
    newsletter_logo,
    newsletter_submit,
} from './styles/newsletter.module.scss';

const Form = () => {
    const [signeUp, setSignedUp] = useState(false);
    const [sending, setSending] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        if (sending) {
            if (typeof formRef !== null) {
                const interval = setInterval(() => {
                    if (formRef.current.style.display === 'none') {
                        setSignedUp(true);
                        clearInterval(interval);
                    }
                }, 100);
            }
        }
    });

    return (
        <div
            id="mlb2-4180717"
            className={`ml-form-embedContainer ml-subscribe-form ml-subscribe-form-4180717 ${container__content__contact__form}`}>
            <div className="ml-form-align-center">
                <div className="ml-form-embedWrapper embedForm">
                    <div
                        className="ml-form-embedBody ml-form-embedBodyDefault row-form"
                        ref={formRef}>
                        <div className="ml-form-embedContent">
                            <h3 className={`${newsletter_header}`}>
                                Zapisz się do Newslettera i bądź na bieżąco!
                            </h3>
                        </div>
                        <form
                            className="ml-block-form"
                            action="https://static.mailerlite.com/webforms/submit/g4c8h2"
                            data-code="g4c8h2"
                            method="post"
                            target="_blank">
                            <div className="ml-form-formContent">
                                <div className="ml-form-fieldRow ml-last-item">
                                    <div
                                        className={`ml-field-group ml-field-email ml-validate-email ml-validate-required ${newsletter_email_field}`}>
                                        <input
                                            aria-label="email"
                                            aria-required="true"
                                            type="email"
                                            className="form-control"
                                            data-inputmask=""
                                            name="fields[email]"
                                            placeholder="Email"
                                        />
                                    </div>
                                </div>
                            </div>
                            <input type="hidden" name="ml-submit" value="1" />
                            <div
                                className={`ml-form-embedSubmit ${newsletter_button}`}>
                                <AnimatedButton
                                    className={
                                        container__content__contact__button
                                    }
                                    submit="true"
                                    onClick={() => setSending(true)}>
                                    Zapisz do newslettera
                                </AnimatedButton>
                            </div>
                            <input type="hidden" name="anticsrf" value="true" />
                        </form>
                    </div>
                    {signeUp && (
                        <div className={newsletter_submit}>
                            <div className="">
                                <h4>Witamy!</h4>
                                <p>
                                    Na Twój adres email została wysłana
                                    wiadomość z prośbą o potwierdzenia zapisu do
                                    Newslettera.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const Newsletter = ({ pageContext }) => {
    const { page } = pageContext;
    return (
        <Layout headerDisabled="true" {...page}>
            <>
                <div className={newsletter_logo}>
                    <AnimatedLink to="/">
                        <img src={Logo} />
                    </AnimatedLink>
                </div>
                <div className={container}>
                    <h1>Newsletter</h1>
                    <h2>
                        Dołącz do nas już teraz i uzyskaj dostęp to wielu
                        ciekawych informacji, wpisów, promocji już teraz.
                    </h2>
                    <p>
                        Nasze wiadomości zaserwują Ci dawkę wielu przydatnych i
                        mięsistych informacji o tym jak w 2021 roku wyróżnić się
                        z tłumu tysięcy podobnych osób ubiegających się o swoją
                        pierwszą pracę w IT. Postaramy się przekazać Ci cenne
                        wskazówki jak wyjść przed szereg, a nie zaginąć jako
                        zwykłe CV pomiędzy setką innych papierów. Praca
                        programisty to przede wszytkim ciągły rozwój w którym
                        chcemy Ci pomóc. Na pewno się nie zawiedziesz!
                    </p>
                    <div className={container__content}>
                        <div className={container__content__contact}>
                            <Form />
                        </div>
                    </div>
                </div>
            </>
        </Layout>
    );
};

export default Newsletter;
